import axios from 'axios';
import { getUser } from './storage';

export const URL = "https://shopbackend.mycourses-shop.com/"

/*const user = getUser()
//console.log(user)
const request = axios.create({
    baseURL: URL,
    //withCredentials: false,
    headers: {
        'Accept':'application/json',
        //"Content-Type":"multipart/form-data",
        'Authorization' : `Bearer ${user?.token}`,
        //'Content-Type': 'application/json'
    },
});*/

const request = axios.create({
    baseURL: URL+"api/",
    headers: {
        'Accept': 'application/json',
        //'Content-Type': 'multipart/form-data',
    },
});

request.interceptors.request.use(config => {
    const user = getUser();
    if (user?.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
});


export default request