import { useEffect, useRef, useState } from "react";
import { DeleteIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import endPoint from "../services/endPoint";
import request, { URL } from "../services/request";
import { Input } from "../components/Input";
import { useFormik } from "formik";
import { pagination } from "../services/function";
import { ArticleIcon } from "../assets/icons/ArticleIcon";

const initProduit = {
  label: "",
  code: "",
};
export const ListCategorie = ({setRefresh}) => {
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState("");
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });

  const close = useRef();
  const closeDelete = useRef();
  useEffect(() => {
    get();
  }, []);
  const formik = useFormik({
    initialValues: initProduit,
    onSubmit: (values) => {
      console.log(values);
      if (values.id) {
        update(values);
      } else {
        post(values);
      }
    },
  });

  

  const get = () => {
    request
      .get(endPoint.categories)
      .then((res) => {
        const tab = pagination(res.data, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data,
            small: tab.list[0],
          });
          setPages(tab);
        }
        setRefresh((old) => old + 1)

      })
      .catch((error) => {
        console.log(error);
      });
  };
  const post = (values) => {
    request
      .post(endPoint.categories, values)
      .then((res) => {
        console.log(res.data);
        close.current.click();
        formik.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const update = (values) => {
    request
      .put(endPoint.categories + "/" + values.id, values)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        formik.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const destroy = (e) => {
    e.preventDefault();

    request
      .delete(endPoint.categories + "/" + viewData.id)
      .then((res) => {
        //console.log(res.data);
        closeDelete.current.click();
        formik.resetForm();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  

  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const editData = (e, data) => {
    e.preventDefault();
    console.log(data);
    formik.setFieldValue("id", data.id);
    formik.setFieldValue("label", data.label);
    formik.setFieldValue("code", data.code);
  };

  const selectedData = (e, data) => {
    e.preventDefault();
    setViewData(data);
  };
  return (
    <>
      <button ref={close} data-bs-dismiss="modal" hidden></button>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Nos Catégories de produits</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center me-auto">
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rechercher..."
                  />
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
              <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#produit"
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Catégories</th>
                <th scope="col">Code</th>
                <th scope="col">Créer le</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                if (data.deleted) {
                  return null;
                }
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>
                      <div className="d-flex">
                        <div className="ms-2">{data.label}</div>
                      </div>
                    </td>
                    <td>{data.code}</td>
                    <td>{new Date(data.createdAt).toLocaleDateString()}</td>
                    <td>
                      <div className="btn-group1">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#view"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EyeIcon /> Voir
                        </button>

                        <button
                          className="btn btn-success mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#produit"
                          onClick={(e) => editData(e, data)}
                        >
                          <EditIcon /> Modifier
                        </button>
                        <button
                          className="btn btn-danger mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <DeleteIcon /> Supprimer
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="modal fade"
        id="produit"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Ajout d'une nouvelle catégorie
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Input
                type={"text"}
                placeholder="Label de la catégorie"
                name={"label"}
                formik={formik}
              />
              <Input
                type={"text"}
                placeholder="Code de la catégorie"
                name={"code"}
                formik={formik}
              />
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={close}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formik.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="delete"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Supprimer les données
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">Voulez-vous continuer ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeDelete}
              >
                Non
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={destroy}
              >
                Oui
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade modal-lg" id="view">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Détails
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-flex">
                <div className="ps-3">
                  <span className="fw-bold fs-20">{viewData.label}</span><br />
                  <span>Code : </span>
                  <span className="text-muted">{viewData.code}</span><br /><br/>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
