import { useEffect, useRef, useState } from "react";
import { DeleteIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import endPoint from "../services/endPoint";
import request, { URL } from "../services/request";
import { Input } from "../components/Input";
import { useFormik } from "formik";
import { pagination } from "../services/function";
import { ArticleIcon } from "../assets/icons/ArticleIcon";

export const ListTransaction = ({ setRefresh }) => {
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState("");
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });
  
  const [stock, setStock] = useState("add");
  const closeStock = useRef();
  useEffect(() => {
    get();
  }, []);

  const get = () => {
    request
      .get(endPoint.transactions)
      .then((res) => {
        console.log(res.data);
        const tab = pagination(res.data.rupturesStock.data, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.rupturesStock.data,
            small: tab.list[0],
          });
          setPages(tab);
        }
        setRefresh((old) => old + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formikStock = useFormik({
    initialValues: { quantite: "", motif: "add" },
    onSubmit: (values) => {
      values.stockId = viewData.stockId;
      values.motif = stock;
      values.quantite = parseInt(values.quantite);
      //console.log(values);
      postStock(values);
    },
  });

  const postStock = (values) => {
    const { stockId, ...data } = values;
    console.log(data);
    request
      .post(endPoint.transactions + "/" + stockId, data)
      .then((res) => {
        console.log(res.data);
        closeStock.current.click();
        //formikPromo.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const selectedData = (e, data) => {
    e.preventDefault();
    console.log(data);
    setViewData(data);
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Liste des transactions</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center me-auto">
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rechercher..."
                  />
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
              <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#produit"
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Produits</th>
                <th scope="col">Prix</th>
                <th scope="col">Stock (Qté)</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>
                      <div className="d-flex">
                        <img
                          width={"40px"}
                          height={"40px"}
                          src={URL + "produit/images/" + data.image}
                          alt=""
                        />
                        <div className="ms-2">{data.label}</div>
                      </div>
                    </td>
                    <td>{data.prix} XOF</td>
                    <td>{data.quantite}</td>
                    <td>
                      <div className="btn-group1">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#view"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EyeIcon /> Voir
                        </button>

                        <button
                          className="btn btn-primary-light mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#stock"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EditIcon /> Stock
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>


      <div className="modal fade modal-lg" id="view">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Détails
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-flex">
                <img
                  width={"160px"}
                  height={"160px"}
                  src={URL + "produit/images/" + viewData.image}
                  alt=""
                />
                <div className="ps-3">
                  <span className="fw-bold fs-20">{viewData.label}</span>
                  <br />
                  <span>quantité : </span>
                  <span className="text-muted">{viewData.quantite}</span>
                  <br />
                  <br />
                  <span>Prix : </span>
                  <span className="fw-bold">{viewData.prix + " FCFA"}</span>
                  <br />
                  <br />
                  <span className="fw-bold">Description</span>
                  <p className="">{viewData.description}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="stock"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Ajout de stock
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-inline-block bg-primary-light mb-3">
                <div
                  className={`btn ${stock === "add" && "bg-primary"}`}
                  onClick={(e) => setStock("add")}
                >
                  Ajout de stock
                </div>
                <div
                  className={`btn ${stock === "remove" && "bg-primary"}`}
                  onClick={(e) => setStock("remove")}
                >
                  Rétrait de stock
                </div>
              </div>
              <Input
                type={"text"}
                placeholder="Entrez la quantité"
                name={"quantite"}
                formik={formikStock}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeStock}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formikStock.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
