import { useEffect, useRef, useState } from "react";
import { DeleteIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import endPoint from "../services/endPoint";
import request, { URL } from "../services/request";
import { Input } from "../components/Input";
import { useFormik } from "formik";
import { pagination } from "../services/function";
import { ArticleIcon } from "../assets/icons/ArticleIcon";
import { ListProduit } from "./ListProduit";
import { ListCategorie } from "./ListCategorie";
import { ListStock } from "./ListStock";
import { ListPromotion } from "./ListPromotion";
import { IconContainer } from "../components/IconContainer";
import { ListTransaction } from "./ListTransaction";

const initProduit = {
  label: "",
  code: "",
  description: "",
  categories: "",
  prix: "",
  image: "",
  boutiqueId: 1,
};
export const Produit = () => {
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [categories, setCategories] = useState([]);
  const [viewData, setViewData] = useState("");
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });
  const stats = [
    { name: "Catégories", value: "1250", icon: <ArticleIcon /> },
    { name: "Produits", value: "525", icon: <ArticleIcon /> },
    { name: "Rupture de Stocks", value: "325", icon: <ArticleIcon /> },
    { name: "Transactions", value: "325", icon: <ArticleIcon /> },
    { name: "Promotions", value: "325", icon: <ArticleIcon /> },
  ];
  const [stat, setStat] = useState({
    nombreProduit: 0,
    nombreCategorie: 0,
    nombreRuptureStock: 0,
    nombrePromotion: 0,
  });
  const close = useRef();
  const [viewList, setViewList] = useState(stats[0].name);
  const [totalCategorie, setTotalCategorie] = useState(0);
  const [totalProduit, setTotalProduit] = useState(0);
  const [totalStock, setTotalStock] = useState(0);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [totalPromotion, setTotalPromotion] = useState(0);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    getStat();
  }, [refresh]);

  const getStat = () => {
    request
      .get(
        endPoint.statistiques +
          "/nombre/produits-categories-rupture-promotions/boutique"
      )
      .then((res) => {
        console.log(res.data);
        setStat(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="row row-cols-1 row-cols-md-5 mb-4">
        <div className="col" onClick={(e) => setViewList(stats[0].name)}>
          <div
            className={`border text-start p-2 rounded-3 ${
              viewList === stats[0].name ? " bg-yellow" : " bg-primary-light"
            }`}
          >
            <IconContainer>{stats[0].icon}</IconContainer>
            <br />
            <span>{stats[0].name}</span> <br />
            <span className="fw-bold">{stat.nombreCategorie}</span>
          </div>
        </div>
        <div className="col" onClick={(e) => setViewList(stats[1].name)}>
          <div
            className={`border text-start p-2 rounded-3 ${
              viewList === stats[1].name ? " bg-yellow" : " bg-primary-light"
            }`}
          >
            <IconContainer>{stats[1].icon}</IconContainer>
            <br />
            <span>{stats[1].name}</span> <br />
            <span className="fw-bold">{stat.nombreProduit}</span>
          </div>
        </div>
        <div className="col" onClick={(e) => setViewList(stats[2].name)}>
          <div
            className={`border text-start p-2 rounded-3 ${
              viewList === stats[2].name ? " bg-yellow" : " bg-primary-light"
            }`}
          >
            <IconContainer>{stats[2].icon}</IconContainer>
            <br />
            <span>{stats[2].name}</span> <br />
            <span className="fw-bold">{stat.nombreRuptureStock}</span>
          </div>
        </div>
        {/**
           * <div className="col" onClick={(e) => setViewList(stats[3].name)}>
          <div
            className={`border  text-start p-2 rounded-3 ${
              viewList === stats[3].name ? " bg-yellow" : " bg-primary-light"
            }`}
          >
            <IconContainer>{stats[3].icon}</IconContainer>
            <br />
            <span>{stats[3].name}</span> <br />
            <span className="fw-bold">{totalTransaction}</span>
          </div>
        </div>
           */}
        <div className="col" onClick={(e) => setViewList(stats[4].name)}>
          <div
            className={`border  text-start p-2 rounded-3 ${
              viewList === stats[4].name ? " bg-yellow" : " bg-primary-light"
            }`}
          >
            <IconContainer>{stats[4].icon}</IconContainer>
            <br />
            <span>{stats[4].name}</span> <br />
            <span className="fw-bold">{stat.nombrePromotion}</span>
          </div>
        </div>
      </div>

      {viewList === stats[0].name && <ListCategorie setRefresh={setRefresh} />}
      {viewList === stats[1].name && <ListProduit setRefresh={setRefresh} />}
      {viewList === stats[2].name && <ListStock setRefresh={setRefresh} />}
      {viewList === stats[3].name && (
        <ListTransaction setRefresh={setRefresh} />
      )}
      {viewList === stats[4].name && <ListPromotion setRefresh={setRefresh} />}
    </>
  );
};
