import { useContext, useEffect, useRef, useState } from "react";
import { ArticleIcon } from "../assets/icons/ArticleIcon";
import { PeopleIcon } from "../assets/icons/ClientIcon";
import { DashIcon } from "../assets/icons/DashIcon";
import { DeleteIcon, DeteleIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { NotificationIcon } from "../assets/icons/NotificationIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import { SuperMarcherIcon } from "../assets/icons/SuperMarcherIcon";
import { ViewArea } from "../components/ViewArea";
import { ViewBar } from "../components/ViewBar";
import request from "../services/request";
import endPoint from "../services/endPoint";
import { AppContext } from "../services/context";
import { useNavigate } from "react-router-dom";
import AppLink from "../routers/AppLink";
import { IconContainer } from "../components/IconContainer";
import { Input } from "../components/Input";
import { useFormik } from "formik";
import { commandeLabel } from "../utils/data";

export const Accueil = () => {
  const authCtx = useContext(AppContext);
  const { user } = authCtx;
  const [datas, setDatas] = useState([]);
  const navigate = useNavigate();
  const [total, setTotal] = useState({
    nomBoutique: "",
    totalVente: "0",
    totalCommande: "0",
    TotalCoutLivraison: "0",
    TotalLivraison: "0",
    TotalCommission: "0",
  });
  const [commande, setCommande] = useState([]);
  const close = useRef();
  const stats = [
    { name: "Commande totale", value: "0", icon: <SuperMarcherIcon /> },
    { name: "Commandes en cours", value: "0", icon: <SuperMarcherIcon /> },
    { name: "Commandes livres", value: "0", icon: <SuperMarcherIcon /> },
    { name: "Commande annuler", value: "0", icon: <SuperMarcherIcon /> },
    { name: "Vente totale", value: "0 FCFA", icon: <ArticleIcon /> },
    { name: "Produits", value: "0", icon: <ArticleIcon /> },
  ];

  useEffect(() => {
    /*getStatVente();
    getStatQuantite();
    */
    //getBoutique()
    //statByIntervalle();
    const values = {
      boutique: "1",
      startDate: "2024-01-07",
      endDate: "2024-01-08",
    };
    statByIntervalle(values)
    get();
  }, []);

  const getStatVente = () => {
    request
      .get(endPoint.statistiques_vente)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatQuantite = () => {
    request
      .get(endPoint.statistiques_quantite)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      //console.log(values)
      statByIntervalle(values);
    },
  });

  const statByIntervalle = (values) => {
    request
      .put(
        endPoint.statistiques +
          "/statistics-by-boutique-connected-by-date-interval",
        values
      )
      .then((res) => {
        console.log(res.data);
        const totalStat = res.data.total.length !== 0 ? res.data.total[0] : "";
        setTotal(totalStat);
        setCommande(res.data.totalGroupByStatus);
        close.current.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const get = () => {
    request
      .get(endPoint.commandes + "/dix-dernieres/commandes")
      .then((res) => {
        console.log(res.data);
        setDatas(res.data.commandes);
        //const tab = pagination(res.data.commandes, 10);

        //console.log(tab);

        /*if (tab.counter !== 0) {
          setDatas({
            all: res.data.commandes,
            small: tab.list[0],
          });
          setPages(tab);
        }*/
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const gotoDetail = (e, id) => {
    e.preventDefault();
    navigate("/dashboard/commandes/" + AppLink.detail + "/" + id);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="text-start mb-3">Accueil</h1>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-5 mb-4">
        <div className="col cursor mb-3">
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>
              <SuperMarcherIcon />
            </IconContainer>{" "}
            <br />
            <span>Total commande</span> <br />
            <span className="fw-bold">{total.totalCommande}</span>
          </div>
        </div>
        <div className="col cursor mb-3">
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>
              <SuperMarcherIcon />
            </IconContainer>{" "}
            <br />
            <span>Total Vente</span> <br />
            <span className="fw-bold">{total.totalVente + " FCFA"}</span>
          </div>
        </div>
        <div className="col cursor mb-3">
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>
              <SuperMarcherIcon />
            </IconContainer>{" "}
            <br />
            <span>Total livraison</span> <br />
            <span className="fw-bold">{total.TotalLivraison}</span>
          </div>
        </div>
        <div className="col cursor mb-3">
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>
              <SuperMarcherIcon />
            </IconContainer>{" "}
            <br />
            <span>Cout livraison</span> <br />
            <span className="fw-bold">
              {total.TotalCoutLivraison + " FCFA"}
            </span>
          </div>
        </div>
        <div className="col cursor mb-3">
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>
              <SuperMarcherIcon />
            </IconContainer>{" "}
            <br />
            <span>Total commission</span> <br />
            <span className="fw-bold">{total.TotalCommission + " FCFA"}</span>
          </div>
        </div>
        {commande.map((data) => {
          return (
            <div className="col cursor mb-3">
              <div className="border text-start p-2 bg-primary-light rounded-3">
                <IconContainer>
                  <SuperMarcherIcon />
                </IconContainer>{" "}
                <br />
                <span>
                  {commandeLabel[data.status]} : {data.nombre_de_commandes}
                </span>{" "}
                <br />
                <span className="fw-bold">
                  Montant total : {data.montant_total + " FCFA"}
                </span>
              </div>
            </div>
          );
        })}
      </div>

      <div className="row mb-4">
        
        <div className="col-12 col-md-6 text-start">
          <span className="fw-bold fs-20">Statistique des commandes</span>
          <ViewBar />
        </div>
        <div className="col-12 col-md-6 text-start">
          <span className="fw-bold fs-20">Statistique des Ventes</span>
          <ViewArea />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-start mb-3">
          <span className="fw-bold fs-20">
            10 dernière commandes de la journée
          </span>
        </div>
        <div className="col-12">
          <div className="col-12">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Numéro</th>
                  <th scope="col">Date</th>
                  {/**<th scope="col">client</th> */}
                  <th scope="col">Total</th>
                  <th scope="col">Statut</th>
                  <th scope="col">Détail</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data, idx) => {
                  if (idx >= 10) {
                    return null;
                  }
                  return (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data.codeCommande}</td>
                      <td>
                        {new Date(data.dateCommande).toLocaleDateString()}
                      </td>
                      {
                        /**
                         * <td>
                        <span>
                          {data.client?.nom + " " + data.client?.prenom}
                        </span>{" "}
                        <br />
                      </td>
                         */
                      }
                      <td>
                        <span>{data.montantTotal + " FCFA"}</span>
                      </td>
                      <td>
                        <span>{data.status}</span>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            className="btn btn-primary-light mx-1 rounded-3"
                            onClick={(e) => gotoDetail(e, data.id)}
                          >
                            <EyeIcon /> Voir
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="filtre"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Filtrage de données par date
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <span className="d-block mb-3">Date de début</span>
              <Input
                type={"date"}
                placeholder=""
                name={"startDate"}
                formik={formik}
              />
              <span className="d-block mb-3">Date de fin</span>
              <Input
                type={"date"}
                placeholder=""
                name={"endDate"}
                formik={formik}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={close}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formik.handleSubmit}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
