import { useFormik } from "formik";
import { DeleteIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import { Input } from "../components/Input";
import request, { URL } from "../services/request";
import endPoint from "../services/endPoint";
import { useEffect, useRef, useState } from "react";
import { pagination } from "../services/function";

const initData = {
  dateDebut: "",
  dateFin: "",
  entreprise: "",
  montant: "0",
  slider: "",
  paiementStatus: false,
};
export const Publicite = () => {

  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState({});
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });
  const [stock, setStock] = useState("");

  const close = useRef();
  const closeDelete = useRef();
  useEffect(() => {
    get();
  }, []);
  const formik = useFormik({
    initialValues: initData,
    onSubmit: (values) => {
      console.log(values);
      if (values.id) {
        update(values);
      } else {
        post(values);
      }
    },
  });
  
  const get = () => {
    request
      .get(endPoint.sliders)
      .then((res) => {
        console.log(res.data)
        const tab = pagination(res.data.sliders, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.produits,
            small: tab.list[0],
          });
          setPages(tab);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const post = (values) => {
    request
      .post(endPoint.sliders, values)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        formik.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const update = (values) => {
    request
      .put(endPoint.sliders + "/" + values.id, values)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        formik.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const destroy = (e) => {
    e.preventDefault();

    request
      .delete(endPoint.sliders + "/" + viewData.id)
      .then((res) => {
        //console.log(res.data);
        closeDelete.current.click();
        formik.resetForm();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editData = (e, data) => {
    e.preventDefault();
    console.log(data);
    formik.setFieldValue("id", data.id);
    formik.setFieldValue("dateDebut", data.dateDebut);
    formik.setFieldValue("dateFin", data.dateFin);
    formik.setFieldValue("entreprise", data.entrepise);
    formik.setFieldValue("montant", data.montant);
    formik.setFieldValue("slider", data.slider);
    formik.setFieldValue("paiementStatus", data.paiementStatus);
    





  };

  const selectedData = (e, data) => {
    e.preventDefault();
    setViewData(data);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Liste des publicités</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center me-auto">
              <div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Rechercher..."
                  />
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
              <div>
                <span className="ms-2">
                  <PrevIcon />
                </span>
                <span className="ms-2">
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">Page 1 / 2</span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#publicite"
                onClick={e => {
                  formik.resetForm()
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Image</th>
                <th scope="col">Entreprise</th>
                <th scope="col">Date de début</th>
                <th scope="col">Date de fin</th>
                <th scope="col">Etat</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td><img
                          width={"40px"}
                          height={"40px"}
                          src={URL + "sliders/images/" + data.image}
                          alt=""
                        /></td>
                    <td>{data.entrepise}</td>
                    <td>{data.dateDebut}</td>
                    <td>{data.dateFin}</td>
                    <td>{data.status}</td>
                    <td>
                    <div className="btn-group">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#view"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EyeIcon /> Voir
                        </button>
                        <button
                          className="btn btn-success mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#publicite"
                          onClick={(e) => editData(e, data)}
                        >
                          <EditIcon /> Modifier
                        </button>
                        <button
                          className="btn btn-danger mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <DeleteIcon /> Supprimer
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade"
        id="publicite"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Creation d'une publicité
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <>
                <Input
                  type={"text"}
                  label="Entreprise"
                  placeholder="Nom de l'entreprise"
                  name={"entreprise"}
                  formik={formik}
                />
                <Input
                  type={"date"}
                  label="Date de début"
                  name={"dateDebut"}
                  formik={formik}
                />
                <Input
                  type={"date"}
                  label="Date de fin"
                  name={"dateFin"}
                  formik={formik}
                />

                <Input
                  type={"file"}
                  label="Fichier image de la publicité"
                  name={"slider"}
                  formik={formik}
                />
              </>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={close}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formik.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="delete"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Supprimer les données
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">Voulez-vous continuer ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeDelete}
              >
                Non
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={destroy}
              >
                Oui
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade modal-lg" id="view">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Détails
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-flex">
                <img
                  width={"160px"}
                  height={"160px"}
                  src={URL + "sliders/images/" + viewData.image}
                  alt=""
                />
                <div className="ps-3">
                  <span className="fw-bold fs-20">{viewData.entrepise}</span>
                  <br />
                  <span>Statut : </span>
                  <span className="fw-bold">
                  {viewData.status}
                  </span>
                  <br />
                  <br />
                  <span>Paiement : </span>
                  <span className="fw-bold">{viewData.paiementStatus ? "Payer" : "Non payer"}</span>
                  <br />
                  <br />
                  <span>Début : </span>
                  <span className="fw-bold">{viewData.dateDebut}</span>
                  <br />
                  <br />
                  <span>Fin : </span>
                  <span className="fw-bold">{viewData.dateFin}</span>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
