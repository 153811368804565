const endPoint = {
  login: "authenticate",
  connected: "authenticate/user-connected",
  boutiques: "boutiques",
  produits: "produits",
  categories: "categories",
  promotions: "promotions",
  transactions: "transactions",
  statistiques_vente: "statistiques/my-statistiques-vente-boutique",
  statistiques_quantite: "statistiques/my-statistiques-quantite-status-boutique",
  statistiques:"statistiques",
  users:"users",
  commandes:"commandes",
  sliders:"sliders",
  sousresponsables:"sousresponsables",
};

export default endPoint;
