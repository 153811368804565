
const AppLink = {
    all:"/*",
    login:"/",
    index:"/",
    detail:"detail",
    detail_id:"detail/:id",
    dashboard:"/dashboard",
    accueil:"accueil",
    commandes:"commandes",
    produits:"produits",
    paiements:"paiements",
    personnel:"le-personnel",
    boiteReception:"boite-reception",
    monCompte:"mon-compte",
    statistique:"statistique",
    publicite:"publicite"
}

export default AppLink