const statut_commande = [
  { id: "EN_ATTENTE", label: "En attente" },
  { id: "EN_COURS_DE_TRAITEMENT", label: "En cours de traitement" },
  { id: "PRET", label: "prête" },
  { id: "EN_COURS_DE_LIVRAISON", label: "En cours de livraison" },
  { id: "LIVRER", label: "Livrée" },
  { id: "RETIRER", label: "Retirée" },
  { id: "ANNULER", label: "Annulée" },

];

const commandeLabel = {
  EN_COUR_DE_PREPARATION: "En cours de préparation",
  ANNULER: "Annulée",
  PRET: "Prête",
  EN_COURS_DE_LIVRAISON: "En cours de livraison",
  LIVREE: "Livrée",
  RETIREE: "Retirée",
};

export { statut_commande, commandeLabel };
