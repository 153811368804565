import { useContext, useEffect, useRef, useState } from "react";
import { DeleteIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import endPoint from "../services/endPoint";
import request, { URL } from "../services/request";
import { Input } from "../components/Input";
import { useFormik } from "formik";
import { pagination } from "../services/function";
import { ArticleIcon } from "../assets/icons/ArticleIcon";
import { AppContext } from "../services/context";

const initProduit = {
  label: "",
  code: "",
  description: "",
  categories: "",
  prix: "",
  image: "",
  boutiqueId: 1,
};
const initPromo = {
  dateDebut: "",
  dateFin: "",
  prixPromotion: "",
  status: "",
};
export const ListProduit = ({ setRefresh }) => {
  const authCtx = useContext(AppContext);
  const { user } = authCtx;
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [categories, setCategories] = useState([]);
  const [viewData, setViewData] = useState({ categories: [] });
  const [isFileForm, setIsFileForm] = useState(false);
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });
  const [stock, setStock] = useState("add");

  const close = useRef();
  const closeDelete = useRef();
  const closePromo = useRef();
  const closeStock = useRef();
  const closeEditImage = useRef()
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  useEffect(() => {
    get();
    getCategories();
  }, []);
  const formik = useFormik({
    initialValues: initProduit,
    onSubmit: (values) => {
      if (isFileForm) {
        console.log(values);
        postFile(values);
      } else {
        if (values.id) {
          update(values);
        } else {
          post(values);
        }
      }
    },
  });
  const formikPromo = useFormik({
    initialValues: initPromo,
    onSubmit: (values) => {
      values.produit = viewData.id;

      console.log(values);
      postPromotion(values);
    },
  });

  const formikProduitImage = useFormik({
    initialValues: {image:""},
    onSubmit: (values) => {
      values.id = viewData.id;

      console.log(values);
      updateImage(values);
    },
  });

  const formikStock = useFormik({
    initialValues: { quantite: "", motif: "add" },
    onSubmit: (values) => {
      values.stockId = viewData.stock.id;
      values.motif = stock;
      values.quantite = parseInt(values.quantite);
      //console.log(values);
      postStock(values);
    },
  });
  const postStock = (values) => {
    const { stockId, ...data } = values;
    console.log(data);
    request
      .post(endPoint.transactions + "/" + stockId, data)
      .then((res) => {
        console.log(res.data);
        closeStock.current.click();
        formikPromo.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const postPromotion = (values) => {
    request
      .post(endPoint.promotions, values)
      .then((res) => {
        //console.log(res.data);
        closePromo.current.click();
        formikPromo.resetForm();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const get = () => {
    request
      .get(endPoint.produits + "/boutique/"+user.boutique.id)
      .then((res) => {
        console.log(res.data);
        const tab = pagination(res.data, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data,
            small: tab.list[0],
          });
          setPages(tab);
        }
        setRefresh((old) => old + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const post = (values) => {
    request
      .post(endPoint.produits, values, header)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        formik.resetForm();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const update = (values) => {
    request
      .put(endPoint.produits + "/" + values.id, values)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        formik.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateImage = (values) => {
    request
      .put(endPoint.produits + "/update-produit-image/" + values.id, values,header)
      .then((res) => {
        //console.log(res.data);
        closeEditImage.current.click();
        formik.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const destroy = (e) => {
    e.preventDefault();

    request
      .delete(endPoint.produits + "/" + viewData.id)
      .then((res) => {
        //console.log(res.data);
        closeDelete.current.click();
        formik.resetForm();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCategories = () => {
    request
      .get(endPoint.categories)
      .then((res) => {
        console.log(res.data);
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postFile = (values) => {
    request
      .post(endPoint.produits + "/file/create", values)
      .then((res) => {
        console.log(res.data);
        close.current.click();
        formik.resetForm();

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const editData = (e, data) => {
    e.preventDefault();
    console.log(data);
    formik.setFieldValue("id", data.id);
    formik.setFieldValue("label", data.label);
    formik.setFieldValue("code", data.code);
    formik.setFieldValue("description", data.description);
    formik.setFieldValue("prix", data.prix);
    formik.setFieldValue("categories", data.categories[0].id);
  };

  const selectedData = (e, data) => {
    e.preventDefault();
    setViewData(data);
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Nos produits</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center me-auto">
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rechercher..."
                  />
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
              <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#produit"
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Produits</th>
                <th scope="col">Catégories</th>
                <th scope="col">Prix</th>
                <th scope="col">Stock (Qté)</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={"produit" + idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>
                      <div className="d-flex">
                        <img
                          width={"40px"}
                          height={"40px"}
                          src={URL + "" + data.image}
                          alt=""
                        />
                        <div className="ms-2 text-start">
                          <span>{data.nom}</span> <br />
                          <span
                            className="fs-10 text-decoration-underline fw-bold px-1 text-danger cursor"
                            data-bs-toggle="modal"
                            data-bs-target="#editimage"
                            onClick={(e) => selectedData(e, data)}
                          >
                            modifier l'image
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      
                    </td>
                    <td>{data.prix} XOF</td>
                    <td>{data.stock?.quantite}</td>
                    <td>
                      <div className="btn-group1">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#view"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EyeIcon /> Voir
                        </button>

                        <button
                          className="btn btn-primary-light mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#promotion"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EditIcon /> Promo
                        </button>
                        <button
                          className="btn btn-primary-light mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#stock"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EditIcon /> Stock
                        </button>
                        <button
                          className="btn btn-success mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#produit"
                          onClick={(e) => editData(e, data)}
                        >
                          <EditIcon /> Modifier
                        </button>
                        <button
                          className="btn btn-danger mx-1 rounded-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <DeleteIcon /> Supprimer
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="modal fade"
        id="produit"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Ajout d'un nouveau produit
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-flex mb-3">
                <div className="d-inline-block bg-primary-light mb-3">
                  <div
                    className={`btn ${isFileForm === false && "bg-primary"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsFileForm(!isFileForm);
                    }}
                  >
                    Ajout simple
                  </div>
                  <div
                    className={`btn ${isFileForm === true && "bg-primary"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsFileForm(!isFileForm);
                    }}
                  >
                    {" "}
                    Ajout multiple
                  </div>
                </div>
              </div>
              {!isFileForm ? (
                <>
                  <Input
                    type={"text"}
                    placeholder="Label du produit"
                    name={"label"}
                    formik={formik}
                  />
                  <Input
                    type={"text"}
                    placeholder="Code du produit"
                    name={"code"}
                    formik={formik}
                  />
                  <Input
                    type={"text"}
                    placeholder="Description du produit"
                    name={"description"}
                    formik={formik}
                  />
                  <Input
                    type={"text"}
                    placeholder="Prix du produit"
                    name={"prix"}
                    formik={formik}
                  />
                  <Input
                    type={"select"}
                    placeholder="categories du produit"
                    name={"categories"}
                    formik={formik}
                    options={categories}
                  />
                  <Input
                    type={"file"}
                    placeholder="image du produit"
                    name={"image"}
                    formik={formik}
                  />
                </>
              ) : (
                <>
                  <Input
                    type={"file"}
                    label="Sélectionnez le fichier xlsx des produits"
                    placeholder="Fichier xlsx des produits"
                    name={"fichierExcel"}
                    formik={formik}
                  />
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={close}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formik.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="delete"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Supprimer les données
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">Voulez-vous continuer ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeDelete}
              >
                Non
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={destroy}
              >
                Oui
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade modal-lg" id="view">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Détails
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-flex">
                <img
                  width={"160px"}
                  height={"160px"}
                  src={URL + "produit/images/" + viewData.image}
                  alt=""
                />
                <div className="ps-3">
                  <span className="fw-bold fs-20">{viewData.label}</span>
                  <br />
                  <span>categorie : </span>
                  <span className="text-muted">
                    {viewData.categories[0]?.label}
                  </span>
                  <br />
                  <br />
                  <span>Prix : </span>
                  <span className="fw-bold">{viewData.prix + " FCFA"}</span>
                  <br />
                  <br />
                  <span className="fw-bold">Description</span>
                  <p className="">{viewData.description}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="promotion"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Ajout le produit en promotion
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <span className="d-block mb-3">
                Date de debut de la promotion
              </span>
              <Input
                type={"date"}
                placeholder="Date de debut de la promotion"
                name={"dateDebut"}
                formik={formikPromo}
              />
              <span className="d-block mb-3">Date de fin de la promotion</span>
              <Input
                type={"date"}
                placeholder="Date de fin de la promotion"
                name={"dateFin"}
                formik={formikPromo}
              />
              <Input
                type={"text"}
                placeholder="Prix promotion"
                name={"prixPromotion"}
                formik={formikPromo}
              />
              <Input
                type={"text"}
                placeholder="Status du produit"
                name={"status"}
                formik={formikPromo}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closePromo}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formikPromo.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="stock"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Ajout de stock
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-inline-block bg-primary-light mb-3">
                <div
                  className={`btn ${stock === "add" && "bg-primary"}`}
                  onClick={(e) => setStock("add")}
                >
                  Ajout de stock
                </div>
                <div
                  className={`btn ${stock === "remove" && "bg-primary"}`}
                  onClick={(e) => setStock("remove")}
                >
                  Rétrait de stock
                </div>
              </div>
              <Input
                type={"text"}
                placeholder="Entrez la quantité"
                name={"quantite"}
                formik={formikStock}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeStock}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formikStock.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editimage"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modification de l'image du produit
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <span className="d-block mb-3">
                Choisissez l'image du produit
              </span>
              <Input
                type={"file"}
                placeholder="Date de debut de la promotion"
                name={"image"}
                formik={formikProduitImage}
              />
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeEditImage}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formikProduitImage.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
